<script>


    import appConfig from "@/app.config";

    import PageHeader from "@/components/structure/page-header";

    import Paginate from "@/components/pagination";
    import Join from "@/components/popups/join";
    import NewTeam from "@/components/popups/newTeam";
    import helper from '@/assets/helpers/helper';
    import UnjoinConfirmation from "@/components/popups/unjoinconfirmation";
    export default {
        page: {
            title: "Teams",
            meta: [{name: "description", content: appConfig.description}]
        },
        components: {UnjoinConfirmation, NewTeam, Join, Paginate, PageHeader},
        data() {
            return {
                teams:[],
                title: "Teams",
                name: 'Teams',
                none:'none',
                current_page: 1,
                last_page_url: 6,
                currentSort:'rank',
                currentSortDir:'ascending',
                selectedId:'',
                filtre_name: "",
                filtre_country: "",
                isLoadedTeams:false,
                loadingTime: 0,
                maxLoadingTime: 3
            };
        },
        created() {

            this.loadTeams(1)
        },
        computed:{
            teams_sorted:function() {
                let teams = this.teams;
                return teams.sort((a,b) => {
                    let modifier = 1;
                    if(this.currentSortDir === 'descending') modifier = -1;
                    if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                    if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
                    return 0;
                });
            }
        },

        methods:{

            async loadTeams(page){
             let teams = await helper.loadData('teams', page);
             if(teams) {
               if (page > 1) {
                 this.teams = Object.keys(teams.data).map(function (el) {
                   let p = teams.data[el];
                   return ({
                     ...p,
                     score: p.score ? parseInt(p.score) : 0,
                     rank: p.ranks.length ? p.ranks[0].rank : '-'
                   });
                 });
               } else this.teams = teams.data.map(p => ({
                 ...p,
                 score: p.score ? parseInt(p.score) : 0,
                 rank: p.ranks.length ? p.ranks[0].rank : '-'
               }))
               this.isLoadedTeams = true
               this.current_page = teams.current_page
               this.last_page_url = teams.last_page;
             }
            },
            refresh(){
                let index =  this.teams.findIndex(team => team.id === this.selectedId);
                this.teams[index].is_member = !this.teams[index].is_member
            },
            show(id,type) {
               this.selectedId = id;
               if(type == 'join') this.$root.$emit('bv::show::modal', 'modal-join')
               if(type == 'unjoin') this.$root.$emit('bv::show::modal', 'unjoin')

            },
            sort:function(s) {
                //if s == current sort, reverse
                if(s === this.currentSort) {
                    this.currentSortDir = this.currentSortDir==='ascending'?'descending':'ascending';
                }
                this.currentSort = s;
            },
            changePage(page) {
                if(this.filtre_name == '' && this.filtre_country == '') this.loadTeams(page)
                else this.filterSearch(page)

            },
            filterSearch(page){
                this.$http
                    .post('teams/search?page='+page,
                        {
                            name  : this.filtre_name,
                            country   : this.filtre_country,
                        })
                    .then(response => {
                        this.teams = response.data.data.map( p => ({ ...p,score:p.score?parseInt(p.score):0,rank:p.ranks.length?p.ranks[0].rank:'-'}));
                        this.current_page  = response.data.current_page;
                        this.last_page_url = response.data.last_page;
                    })
                    .catch(error => {
                        console.log(error)

                    })
            },
        },

        watch: {
            filtre_name:function        ()    {this.filterSearch(1)},
            filtre_country:function     ()    {this.filterSearch(1)},

        },






    };
</script>

<template>
    <div>
        <PageHeader :title="$t('profil_team.title_tab')"/>
        <div class="row">
            <div class="col-xl-11 col-lg-12 col-sm-12 m-auto">
                <div class="card">
                    <div class="card-body">
                        <form>
                            <div class="row mb-2">
                                <div class="col-xl-10 d-flex">

                                <div class="col-xl-2 col-sm-3">
                                    <div class="form-group  mb-0">
                                        <label>{{$t('profil_team.name')}}</label>

                                        <b-form-input :placeholder="$t('profil_team.name_ph')"  v-model="filtre_name"></b-form-input>
                                    </div>
                                </div>

                                    <div class="col-xl-3 col-sm-3">
                                        <div class="form-group  mb-0">
                                            <label>{{$t('profil_team.country')}}</label>
                                            <country-select id="country" :blackList="['EH']" :autocomplete="true"  v-model="filtre_country" class="custom-select" :countryName="true"/>
                                        </div>
                                    </div>

                                </div>
                                <div class="col-xl-2 text-right">
                                    <a class="btn btn-primary" @click="$bvModal.show('modal-create-team')">{{$t('profil_team.create.text')}}</a>
                                </div>

                            </div>
                        </form>
                        <!-- Table -->



                      <div v-if="isLoadedTeams">
                                <div class="row mt-4" v-if="teams.length">
                                  <table class=" tab table b-table text-center " role="table">
                                <thead>
                                <tr>
                                    <th scope="col" @click="sort('rank')" role="columnheader" :aria-sort="(currentSort == 'rank')?currentSortDir:none">#</th>
                                    <th scope="col" @click="sort('name')" role="columnheader" class="text-xl-left pl-xl-3"  :aria-sort="(currentSort == 'name')?currentSortDir:none">{{$t('profil_team.name')}}</th>
                                    <th scope="col" @click="sort('captain.id')" role="columnheader" :aria-sort="(currentSort == 'captain.id')?currentSortDir:none">{{$t('profil_team.captain')}}</th>
                                    <th scope="col" @click="sort('score')" role="columnheader" :aria-sort="(currentSort == 'score')?currentSortDir:none">{{$t('profil_team.score')}}</th>
                                    <th scope="col" @click="sort('country')" role="columnheader" :aria-sort="(currentSort == 'country')?currentSortDir:none">{{$t('profil_team.country')}}</th>

                                    <th scope="col" @click="sort('competitions_count')" role="columnheader" :aria-sort="(currentSort == 'competitions_count')?currentSortDir:none">{{$t('profil_team.competitions')}}</th>
                                    <th scope="col" @click="sort('challenge_solved_count')" role="columnheader" :aria-sort="(currentSort == 'challenge_solved_count')?currentSortDir:none">{{$t('profil_team.solves')}}</th>
                                    <th scope="col" >{{$t('profil_team.action')}}</th>

                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="data of teams_sorted" :key="data.id">
                                    <td data-label="#"> {{data.rank?data.rank:'-'}}</td>
                                    <td :data-label="$t('profil_team.name')" class="text-xl-left">
                                        <b-avatar :src="data.avatar" class="mr-3"></b-avatar>
                                        <router-link :to="{name:'ProfilTeam',params:{id:data.id}}" class="h6 link"> {{data.name}}</router-link>
                                    </td>
                                    <td :data-label="$t('profil_team.captain')" >
                                      <router-link class="text-muted link" :to="{name:'ProfilUser',params:{id:data.captain.user_id}}" v-if="data.captain">{{data.captain.username}}</router-link>
                                      <h6 class="text-muted" v-else> None</h6>
                                    </td>
                                    <td :data-label="$t('profil_team.score')"> {{data.score}}</td>
                                    <td :data-label="$t('profil_team.country')"> {{data.country}}</td>

                                    <td :data-label="$t('profil_team.competitions')"> {{data.competitions_count}}</td>
                                    <td :data-label="$t('profil_team.solves')">{{data.challenge_solved_count}}</td>
                                    <td :data-label="$t('profil_team.action')">
                                        <button type="button" class="btn btn-outline-primary mr-1 btn-sm btn-rounded" @click="show(data.id,'join')" v-if="!data.is_member">{{$t('profil_team.join.text') }}</button>
                                        <button type="button" class="btn btn-outline-primary mr-1 btn-sm btn-rounded" @click="show(data.id,'unjoin')" v-else>{{$t('profil_team.unjoin.text')}}</button>

                                    </td>
                                </tr>


                                </tbody>
                            </table>

                                    <paginate class="mx-auto my-3" :current_page=current_page :last_page_url=last_page_url
                                              v-on:change-page="changePage"/>

                        </div>
                      <p class="text-center my-3" v-else>
                        {{ $t('validation.no_team') }}
                      </p>
                      </div>
                      <div v-else>
                        <b-skeleton-table :rows="10" :columns="8"></b-skeleton-table>
                        <b-skeleton></b-skeleton>
                      </div>

                        <join :id="selectedId" v-on:refresh="refresh"/>
                        <unjoin-confirmation :id="selectedId" type="teams" v-on:refresh="refresh"/>
                        <new-team />

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>